import * as _ from "lodash";
import Aprobada from "../assets/icon/aprobado.png";
import Anulada from "../assets/icon/anulado.png";
import PendienteAprobacion from "../assets/icon/pendienteAprobacion.png";
import { AttributeTypes, FormTypeEnum, ProductDataProps, quoteState } from "./types";
import { IProductAppendChild, IProductData, IProductQuote, IProductQuoteAditionalComplements, IProductQuoteDetail, IQuoteDetailChildProduct, IQuoteDetailQualitative, IQuoteDetailQuantitative } from "../core/models/products";
import { IProductAttr } from "../core/models/attributes";
import { generateProductQualitativeAttributes } from "./helpers/attributesHelper";

const asyncLocalStorage = {
  setItem: function (key: string, value: string) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
};

const getRandomNumber = (length: number) => {
  return Math.floor(
    Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
  );
};

const getBase64FromFile = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const findIndex = (
  arrayElements: any[],
  key: string,
  match: string | number
) => {
  return _.findIndex(arrayElements, (i: any) => {
    return i[key] == match;
  });
};

const cloneWithoutReference = (elements: any) => {
  return JSON.parse(JSON.stringify(elements));
};

const findProductDescription = (
  name: string,
  descriptionId: number | string,
  productChild: any[],
  productAppendChild: IProductAppendChild
) => {
  const index = findIndex(productChild, "descripcion_comercial", name);
  let desc;
  if (index !== -1) {
    const secondIndex = findIndex(
      productChild[index].items,
      "_id",
      descriptionId
    );
    if (secondIndex !== -1) {
      desc = productChild[index].items[secondIndex]["descripcion"];
    }
  } else {
    let response;
    productChild.map((v) => {
      const k = v.descripcion_comercial;
      const pc = productAppendChild[k];
      if (pc && pc.items && pc.items.length > 0) {
        const index = findIndex(pc.items, "descripcion_comercial", name);

        if (index !== -1) {
          const pci = pc.items[index];
          const secondIndex = findIndex(pci.items, "_id", descriptionId);
          if (secondIndex !== -1) {
            response = pci.items[secondIndex]["descripcion"];
            return;
          }
        }
      }
    });
    if (response) {
      desc = response;
    }
  }
  return desc;
};

/**
 * 
 * @param productPrice 
 * @param clientData 
 * @param quantity 
 * @param withCount 
 * @returns finalCost: Costo Final por producto
 */
const getProductFinalCost = (
  productPrice: number,
  clientData: any,
  quantity: number,
  withCount: boolean
):number => 
{
  const bonf = getBonif(clientData);  
  return parseFloat(
    (
      (productPrice - (productPrice * bonf) / 100) *
      (withCount ? quantity : 1)
    ).toFixed(2)
  );
};

/**
 * 
 * @param price 
 * @param quantity 
 * @param profitability 
 * @param bonif 
 * @returns precio de venta por producto
 */
const getProductSalePrice = (
  price:number,
  quantity:number,
  profitability:number,
  bonif:number 
):number => 
{

  quantity = quantity || 1;

  if(price && profitability)
  {
    return parseFloat(((price * quantity - (price * quantity * bonif / 100))
    + ((price * quantity - (price * quantity * bonif / 100)) * profitability / 100)).toFixed(2));
  }

  return parseFloat((price * quantity - (price * quantity * bonif / 100)).toFixed(2));

}

const getBonif = (clientData: any) => {
  const bonf =
    clientData && clientData.client ? clientData.client.bonificacion : 0;
  return bonf;
};

/** 
 * @param clientData 
 * @returns profitability: coeficiente de rentabilidad
 */
const getProfitability = ( clientData:any ) => {

  const { client } = clientData;
  if( !_.isEmpty(client) )
  {
    const { parametro } = client;
    return (!_.isEmpty(parametro)) ? parametro.rentabilidad : 0;
  } 
  
  return 0;
}

const getQuoteState = ( qState: string ) => 
{
  /* 
    para verificar si la cotizacion esta "Pendiente" o "Finalizada"
    de modo que se habilite el boton de ver/editar
  */
  const { pendingApprove, finalized } = quoteState;
  
  return qState == pendingApprove || qState == finalized;  
};

const getQuoteStateId = ( quote: any ) => 
{  
  const { estado_cotizacion: qState } = quote;
  const { pendingApprove, finalized, approved, expired } = quoteState;

  if( qState == approved || qState == finalized )
  {
    return 2;
  }

  if( qState == pendingApprove || qState == expired)
  {
    return 0;
  }

  return parseInt(qState);
};

const verifyNullState = (state: any, draft: boolean) => {
  if (draft) {
    return state == 1 ? false : true;
  }
  return state == 1 || state == 2 ? false : true;
};

const getOrderStateId = (order: any) => {
  let orderId = 0;

  if (order.anulado) {
    orderId = 1;
  } else if (order.aprobado) {
    orderId = 2;
  } else {
    orderId = 0;
  }
  return orderId;
};

const getStateImg = (element: any, elementType: FormTypeEnum) => {
  const elementId =
    elementType == FormTypeEnum.Quote
      ? getQuoteStateId(element)
      : getOrderStateId(element);

  const imgSrc: any = [
    {
      src: PendienteAprobacion,
      alt: "Pendiente de Aprobacion",
    },
    {
      src: Anulada,
      alt: "Anulada",
    },
    {
      src: Aprobada,
      alt: "Aprobada",
    },
  ];
  return <img src={imgSrc[elementId].src} alt={imgSrc[elementId].alt} />;
};

const generateAttrData = ( details: IProductQuoteDetail ) => {
  const attr: any = [];
  const { quantitative } = details;

  _.map(quantitative, ( attribute:IQuoteDetailQuantitative ) => {
    if (attribute.tipodeatributo_id == AttributeTypes.cuantitativoCompuesto) {
      attr.push({
        _id: attribute._id,
        atributo_id: attribute.atributo_id,
        base: attribute.value?.base,
        altura:attribute.value?.altura
      });
    } else {
      attr.push({
        _id: attribute._id,
        atributo_id: attribute.atributo_id,
        valor: attribute.value?.base,
      });
    }
  });
  return attr;
};

/**
 * 
 * @param productData 
 * @param detail 
 * @returns retorna la lista de los atributos CUALITATIVOS pertenecientes a un producto
 * 
 * se reciben una lista de atributos base (que pertenecen al producto padre)
 * y luego se iteran los productos hijos para extraer sus atributos y adjuntarlos
 * a la lista principal que servira para crear los selectores de atributos en pantalla.
 */
const contactChildProductAttributes = ( productData: IProductData, detail: IProductQuoteDetail ) => { 

  /*
    shouldRemove
    Cada vez que se ejecuta esta funcion, se agregan atributos de productos hijos a la lista principal.
    esto genera duplicados en los options de los selectores. Para evitar esto, se coloca el flag "shoulRemove"
    a los elementos de productos hijos agregados.

    de tal manera, al iniciar el proceso  (filter) se quitan los elementos viejos, para agregar limpiamente los nuevos,
    creando estos con el mismo flag.
  */

  const updatedAttributes = (productData.product.editProduct
    ? [...productData.product.atributosDetalle]
    : productData.productById &&
      [...productData.productById.atributos]).filter((attribute:any) => !attribute.shouldRemove);         

  /*
    selectedChildProducts: contiene detalles de productos hijos
    ej: tela, mecanismos, soportes... 
  */
  const selectedChildProducts = detail.childProducts || [];

  /* 
    appendChildProducts: contiene los datos asociados a los productos hijos de productos hijos.
    como por ejemplo, el soporte, que depende del mecanismo.

    descripcion_comercial:"Soportes para enrollables"
    grupoid:"102"
    items:Array(2)
    0: 
      {_id: '1078', codigo: 'MR.SO.', descripcion: 'Soporte simple', cotiza: '0', descripcionadicional: 'Usar en casos de mecanismos 25 y 55 con cadena plastica y soporte blanco', …}
    1: 
      {_id: '1080', codigo: 'MR.SinS.', descripcion: 'Sin soportes', cotiza: '0', descripcionadicional: '', …}
  */
  const appendChildProducts = [];
  const objectKeys = Object.keys(productData.productAppendChild);
  for (let i = 0; i < objectKeys.length; i++) 
  {
    const pc = productData.productAppendChild[objectKeys[i]];
    if (pc.items && pc.items.length > 0) {
      for (let j = 0; j < pc.items.length; j++) {
        const item = pc.items[j];
        appendChildProducts.push(item);
      }
    }
  }

  const allChildProductsData = [ 
        ...productData.productChild, 
        ...appendChildProducts 
      ];

  selectedChildProducts.forEach((currentProduct) =>
  {
    const matchedProduct = allChildProductsData.find((elem) => elem.descripcion_comercial == currentProduct.name);

    if (matchedProduct) 
    {      
      const matchedItem = matchedProduct.items.find(( item ) => item._id == currentProduct.value);
      if (matchedItem) 
      {
        if (matchedItem.atributos && matchedItem.atributos.length > 0) 
        {
          _.map(matchedItem.atributos, (a) => {
            if (a.atributo_item != null) 
            {
              updatedAttributes.push({...a, shouldRemove:true});
            }
          });
        }
      }
    }
  });

  return [ ...updatedAttributes ];
};

/** 
 * CREATE
*/
const generateProductQuoteToRequestDetail = (
  values: any,
  productData: IProductData,
  productPrice: any
) => {

  const { 
    product,
    product :{ productQuoteIndex },
    productQuote } = productData;
  const id         = product.editProduct && ( product._id != product.producto._id ) ? product._id: undefined;
  const itemNumber = product.editProduct ? product.item : productQuote.length + 1;
  
  const detail = generateProductDetailData(productData);

  const newProduct: any = {
    _id                    : id,
    cotizacionId           : product.cotizacionId,
    item                   : itemNumber,
    producto               : product.editProduct ? product.producto : product,
    detalle                : detail,
    cantidad               : values.quantity,
    precio                 : productPrice,
    atributos              : product.editProduct ? product.atributos : productData.attr,
    productosHijos         : product.editProduct ? product.productosHijos : productData.productChild,
    atributosDetalle       : contactChildProductAttributes(productData, detail),
    complementosAdicionales: product.complementosAdicionales ? product.complementosAdicionales : [],
    complementosNecesarios : product.editProduct ? product.complementosNecesarios : []  
  };

  const selectedQualitativesValues  = product.detalle ? [ ...product.detalle.qualitative ]: undefined;

  newProduct.detalle = {
    ...newProduct.detalle,
    qualitative : generateProductQualitativeAttributes( [...newProduct.atributosDetalle], selectedQualitativesValues )
  };  

  if ( product.editProduct && typeof productQuoteIndex == 'number' ) 
  {
    return productQuote.map(( currentProduct:IProductQuote, innerIndex:number )=> {
      /* productQuoteIndex: representa el index del producto que se seleccionó para editar 
      inicializado en ProductDetailMenu.tsx 95 */
      if( innerIndex == productQuoteIndex )
      {
        return newProduct;
      }
      return currentProduct;
    });
  } 

  return [
    ...productQuote,
    newProduct
  ]
};

/** 
 * @param attr {
 *  tipodeatributo_id:number
 *  base: number
 *  altura: number
 * } 
 */
 const parseAttributes = ( attr:IProductAttr ) => {

  if( attr.tipodeatributo_id == AttributeTypes.cuantitativoCompuesto) {
    return { base: attr.base , altura: attr.altura };
  } 
      
  if( attr.tipodeatributo_id == AttributeTypes.cuantitativoSimple) {
    return { base: attr.base };
  }
                          
}

const generateProductDetailData = ( productData: IProductData ) : IProductQuoteDetail => {
  
  const attrs:IProductAttr[] = productData.product.editProduct
    ? productData.product.atributos
    : productData.attr;
  const appendChild = productData.productAppendChild || [];

  const attributes = attrs.map(( attr:IProductAttr ) => {

    const { _id, atributo_id, descripcion_comercial, tipodeatributo_id, value_id } = attr;

    return {
      _id            : value_id || _id,
      atributo_id    : atributo_id,
      item_pedido_id : atributo_id,
      name           : descripcion_comercial,
      value          : parseAttributes(attr),
      tipodeatributo_id : tipodeatributo_id
    }
  });

  const newDetail: IProductQuoteDetail = { quantitative: attributes };

  /*
    Se verifica si esta vacio dado que existe el caso en el que un producto principal
    no tenga productos hijos, como es el caso del producto "R93 - A CORDON - A MEDIDA"
    y al estar vacio, es recibido como {} y no como [] generando un error de mapeado.
  */
  if( !_.isEmpty(appendChild) )
  {
    /* 
      detailChildProducts
      En caso de estar editando un pedido, obtenemos los productos hijos de los "detalle" pues estos contienen el "_id" de referencia
      necesario para la actualización de un pedido.
    */
    const detailChildProducts: IQuoteDetailChildProduct[] = productData.product.editProduct ? productData.product.detalle.childProducts : [];


    newDetail.childProducts = Object.keys(appendChild).map(( key:string ) => 
    {

      /* 
        producto.name y appendChild[key].description
        Ambos representan "descripcion_comercial" del producto.
      */
      const matchedProduct = detailChildProducts.find(( product ) => { return product.name == appendChild[key].description });
      
      return {
        _id         : matchedProduct && matchedProduct._id,
        name        : appendChild[key].description,
        value       : appendChild[key].value,
        description : findProductDescription(
                          appendChild[key].description,
                          appendChild[key].value,
                          productData.productChild,
                          productData.productAppendChild )
      }      
    });
  
  }
  
  return { ...newDetail };
};

/*
  generateDetailDataForDetailPage
  se inicializan los datos para el atributo "detalle" para productData.productQuote[i].detalle
  al entrar en modo edición de un pedido existente.
*/

const generateDetailDataForDetailPage = (values: any) => {

  const formattedQuantiativeAttributes = values.atributos.filter(
    (pa: any) => {
    return pa.atributo.atributo_item == null;
  })
  .map(
    (attr: any) => {
      return {
        _id: attr._id,
        atributo_id: attr.atributo_id,
        item_pedido_id: attr.atributo._id,
        name: attr.atributo.atributo.descripcion_comercial,
        tipodeatributo_id : attr.atributo.atributo.tipodeatributo_id,
        value: { 
            // SOLO PARA LA EDICION 
            base: attr.base || attr.valor, 
            altura: attr.altura 
        }
      };
  });

  const newDetail: IProductQuoteDetail = { quantitative: formattedQuantiativeAttributes };

  if( !_.isEmpty(values.productos_hijos) )
  {
    newDetail.childProducts = values.productos_hijos.map((pc: any) => {
      return {
        _id: pc._id,
        description: pc.producto.descripcion,
        name: pc.producto.grupo.descripcion_comercial,
        value: pc.producto._id,
      };
    });
  }
  
  return { detalle: newDetail };
};


/**
 * 
 * @param values 
 * @param product 
 * @returns retorna la lista de los atributos CUALITATIVOS pertenecientes a un producto
 * 
 * se reciben una lista de atributos base (que pertenecen al producto padre)
 * y luego se iteran los productos hijos para extraer sus atributos y adjuntarlos
 * a la lista principal que servira para crear los selectores de atributos en pantalla.
 */
const contactChildProductAttributesFromOrder = (values: any, product: ProductDataProps) => {

  /*
    shouldRemove
    Cada vez que se ejecuta esta funcion, se agregan atributos de productos hijos a la lista principal.
    esto genera duplicados en los options de los selectores. Para evitar esto, se coloca el flag "shoulRemove"
    a los elementos de productos hijos agregados.

    de tal manera, al iniciar el proceso  (filter) se quitan los elementos viejos, para agregar limpiamente los nuevos,
    creando estos con el mismo flag.
  */
  const updatedAttributtes = [...product.atributos].filter((attribute:any) => !attribute.shouldRemove);

  values.productos_hijos.filter((pc: any) => 
  {
    return pc.producto.atributos && pc.producto.atributos.length > 0;
  }).map((pc:any) => {
    pc.producto.atributos.forEach((pa:any) => {
      updatedAttributtes.push({ ...pa, shouldRemove:true });
    });
  });
  return [ ...updatedAttributtes ];
};

/**
 * 
 * @param index 
 * @param values 
 * @param formType 
 * @param elementFilters 
 * @param product 
 * @param productCompleteData 
 * @returns 
 */
const generateProductDataForDetailPage = (
  index: number,
  values: any,
  formType: any,
  elementFilters: any,
  product: any,
  productCompleteData: any
) => {
  const newProductQuoteEle: any = {
    ...getBaseDetail(values),
    ...generateDetailDataForDetailPage(values),
    _id: values._id,
    item: index + 1,
    producto: product,
    cantidad: values.cantidad,
    precio: values.precio,
    atributos: productCompleteData && productCompleteData.productAttr,
    productosHijos: productCompleteData && productCompleteData.productChild,
    productos_hijos: values.productos_hijos,
    complementosNecesarios: productCompleteData && productCompleteData.productComplement,
    complementosAdicionales: values.complementosAdicionales ? values.complementosAdicionales : [],
    atributosDetalle: contactChildProductAttributesFromOrder(values, product),
  };

  const qualitativeSelected = elementFilters.qualitative.map((h: any) => 
  {
    return {
        descripcion_comercial: h.atributo.atributo.descripcion_comercial,
        atrib_id: h._id, 
        seleccionado: { _id: h.atributo._id,
                        product_id:h.atributo.producto_id,
                        isMainProduct: product._id == h.atributo.producto_id
        } 
    };
  });

  newProductQuoteEle.detalle = {
    ...newProductQuoteEle.detalle,
    qualitative : generateProductQualitativeAttributes( newProductQuoteEle.atributosDetalle, qualitativeSelected )
  }; 
    return { ...newProductQuoteEle };
  };

const loadComplements = async (productElement: any) => {
  const { producto, productosHijos, detalle } = productElement;
  let complements: any = [];
  productosHijos &&
    productosHijos.forEach((value: any) => {
      const detail = detalle?.childProducts?.find(
        (valor: any) => valor.name === value.descripcion_comercial
      );
      if (detail) {
        const itemComplements = value.items.find(
          (valor: any) => valor._id === detail.value
        );
        if (itemComplements) {
          complements = [...complements, ...itemComplements.complementos];
        }
      }
    });
  if (producto.complementos && producto.complementos.length > 0) {
    complements = [...complements, ...producto.complementos];
  }
  return complements;
};


/* 
  esta funcion solo aplica cuando entras en la pantalla de edicion de un producto
  de un pedido/cotizacion existente. y sirve para setear los los default values
  de los atributos cuantitativos.
*/
const getBaseDetail = (values: any) => {
  const response = { ancho: 1, alto: 1 };
  _.filter(values.atributos, (pa: any) => {
    return pa.atributo.atributo_item == null;
  }).map((attr: any) => {
        response.ancho = attr.base || attr.valor;
        response.alto = attr.altura;    
  });

  return response;
};


const getCostAndPriceFromProductQuote = ( productQuote: IProductQuote[], clientData:any ) => {
      
      let totalFinalCost = 0;
      let totalSalePrice = 0;
      
      /*
       Costo Final y Precio de Venta de los PRODUCTOS
      */
      totalFinalCost += _.sumBy( productQuote, ( pq: IProductQuote ) => {
        const { precio, cantidad } = pq;
        return getProductFinalCost(precio, clientData, cantidad, true);  
      });

      totalSalePrice += _.sumBy( productQuote, ( pq: IProductQuote ) => {  
        const { precio, cantidad } = pq;
        return getProductSalePrice(precio,cantidad,getProfitability(clientData),getBonif(clientData));  
      });
  
      /*
       Costo Final y Precio de Venta de los COMPLEMENTOS
      */
      for (let i = 0; i < productQuote.length; i++) {
        const pq = productQuote[i];
        if ( pq.complementosAdicionales?.length ) {

          totalFinalCost += _.sumBy( pq.complementosAdicionales, ( value: IProductQuoteAditionalComplements ) => {
            const { price, quantity } = value; 
            return getProductFinalCost( price, clientData, quantity, true );
          });

          totalSalePrice += _.sumBy( pq.complementosAdicionales, ( value: IProductQuoteAditionalComplements ) => {
            const { price, quantity } = value;
            return getProductSalePrice(price ,quantity , getProfitability(clientData),getBonif(clientData));
          });
        }
      }

      return {
        totalFinalCost,
        totalSalePrice
      };
}

export {
  asyncLocalStorage,
  cloneWithoutReference,
  findIndex,
  findProductDescription,
  getBase64FromFile,
  getBonif,
  getQuoteState,
  getQuoteStateId,
  getOrderStateId,
  getRandomNumber,
  getStateImg,
  generateAttrData,
  getProductFinalCost,
  getProductSalePrice,
  generateProductDataForDetailPage,
  generateProductQuoteToRequestDetail,
  loadComplements,
  verifyNullState,
  getProfitability,
  getCostAndPriceFromProductQuote,
  generateDetailDataForDetailPage
};
